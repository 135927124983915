// extracted by mini-css-extract-plugin
export var RecruitmentDetail = "Job-module--RecruitmentDetail--c1b81";
export var button = "Job-module--button--7bd38";
export var contentBox = "Job-module--contentBox--65b17";
export var contentItem = "Job-module--contentItem--259ec";
export var contentItemLeft = "Job-module--contentItemLeft--7f8b3";
export var detailedInstructions = "Job-module--detailedInstructions--e4d5f";
export var detailedInstructionsTitle = "Job-module--detailedInstructionsTitle--a99bb";
export var home = "Job-module--home--2f850";
export var jobBaseIntroMobile = "Job-module--jobBaseIntroMobile--d454c";
export var jobBaseIntroPc = "Job-module--jobBaseIntroPc--78f89";
export var jobTitle = "Job-module--jobTitle--0f6ec";
export var preClass = "Job-module--preClass--b0690";